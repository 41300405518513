import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq5 = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <div className="back-button mb-4 mt-3">
            <Link to="/faq/">&larr; Back</Link>
          </div>
          <Col sm="12">
            <div className="mb-5">
              <StaticImage
                src="../../images/man-smiling-wht-background.jpg"
                alt="Man thinking"
                placeholder="none"
                layout="fullWidth"
              />
              <h2 className="mt-3">
                How do I find a dentist that prescribes Lumineers
                <sup>&reg;</sup>?
              </h2>
              <p>
                Ask your current dentist if Lumineers<sup>&reg;</sup> are right
                for you. If you do not have a dentist, view our growing
                directory of select dentists that may be in your area.{' '}
              </p>
            </div>
            <div className="mb-5 text-center">
              <Link to="../../find-a-dentist/" className="intro-button mb-5">
                Find a Dentist
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Faq5
